import React, { useState } from "react";
import {
  Container,
  TextField,
  Button,
  Typography,
  Box,
  Chip,
  Paper,
  Alert,
} from "@mui/material";

function App() {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [source, setSource] = useState("");
  const [destinations, setDestinations] = useState([]);
  const [emailInput, setEmailInput] = useState("");
  const [error, setError] = useState("");
  const [requestStatus, setRequestStatus] = useState(null);
  const [requestMessage, setRequestMessage] = useState("");

  const validateEmail = (email) => {
    const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return re.test(email);
  };

  const handleAddEmail = () => {
    if (emailInput && validateEmail(emailInput)) {
      if (!destinations.includes(emailInput)) {
        setDestinations([...destinations, emailInput]);
        setEmailInput("");
        setError("");
      }
    } else {
      setError("Please enter a valid email address.");
    }
  };

  const handleDeleteEmail = (emailToDelete) => {
    setDestinations(destinations.filter((email) => email !== emailToDelete));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const authHeader = "Basic " + btoa(`${username}:${password}`);

    const requestData = {
      source: source,
      destinations: destinations,
    };

    // Determine the base URL based on the current environment
    const baseUrl =
      window.location.hostname === "localhost"
        ? "http://localhost:8778"
        : window.location.origin;

    try {
      const response = await fetch(
        `${baseUrl}/api/v1/forwardemail?domain=tonicloud.eu`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: authHeader,
          },
          body: JSON.stringify(requestData),
        }
      );

      if (response.ok || response.status === 201) {
        setRequestStatus("success");
        setRequestMessage("Email forwarded successfully!");
      } else {
        const errorText = await response.text();
        setRequestStatus("error");
        setRequestMessage(`Error ${response.status}: ${errorText}`);
      }
    } catch (error) {
      setRequestStatus("error");
      setRequestMessage(`Network error: ${error.message}`);
    }
  };

  return (
    <Container maxWidth="sm">
      <Box sx={{ mt: 5 }}>
        <Typography variant="h4" component="h1" gutterBottom>
          Email Forwarder
        </Typography>
        <form onSubmit={handleSubmit}>
          <TextField
            label="Username"
            variant="outlined"
            fullWidth
            margin="normal"
            value={username}
            onChange={(e) => setUsername(e.target.value)}
            required
            autoComplete="username"
          />
          <TextField
            label="Password"
            type="password"
            variant="outlined"
            fullWidth
            margin="normal"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            required
            autoComplete="current-password"
          />
          <TextField
            label="Source Email"
            variant="outlined"
            fullWidth
            margin="normal"
            value={source}
            onChange={(e) => setSource(e.target.value)}
            required
            autoComplete="off"
          />
          <TextField
            label="Add Destination Email"
            variant="outlined"
            fullWidth
            margin="normal"
            value={emailInput}
            onChange={(e) => setEmailInput(e.target.value)}
            onKeyPress={(e) => {
              if (e.key === "Enter") {
                e.preventDefault();
                handleAddEmail();
              }
            }}
            error={!!error}
            helperText={error}
          />
          <Button
            variant="contained"
            color="primary"
            onClick={handleAddEmail}
            sx={{ mt: 1, mb: 2 }}
          >
            Add Email
          </Button>
          <Paper
            variant="outlined"
            sx={{
              display: "flex",
              flexWrap: "wrap",
              listStyle: "none",
              p: 1,
              m: 0,
            }}
            component="ul"
          >
            {destinations.map((email, index) => (
              <li key={index}>
                <Chip
                  label={email}
                  onDelete={() => handleDeleteEmail(email)}
                  sx={{ m: 0.5 }}
                />
              </li>
            ))}
          </Paper>
          <Button
            type="submit"
            variant="contained"
            color="primary"
            fullWidth
            sx={{ mt: 2 }}
          >
            Forward Email
          </Button>
        </form>
        {requestStatus && (
          <Alert severity={requestStatus} sx={{ mt: 2 }}>
            {requestMessage}
          </Alert>
        )}
      </Box>
    </Container>
  );
}

export default App;
